export default class Filters
{
	constructor()
	{
		this.filters = document.getElementById('filters');
		this.filters !== null && this.initFilters();
	}

	initFilters()
	{
		this.initToggles();
		this.initMap();
	}

	initToggles()
	{
		this.form = document.querySelector('.filters form');
		this.toggles = document.querySelectorAll('.filters input[type="radio"]');
		this.results = document.querySelector('.filter-results');

		this.form.oninput = () => this.form.classList.add('changed');

		this.toggles.forEach((toggle) => toggle.onchange = () =>
		{
			if(toggle.value === 'map')
			{
				this.results.classList.add('switch');
				this.map.fitBounds(this.bounds);

				return;
			}

			this.results.classList.remove('switch');
		});
	}

	initMap()
	{
		this.map = document.getElementById('map_search');
		this.businesses = document.querySelectorAll('.business');

		const script = document.getElementById('googleMaps');

		if(script)
		{
			if(typeof window.google === 'undefined')
			{
				setTimeout(() => this.initMap(), 100);
				return;
			}

			this.renderMap();
			return;
		}

		this.script = document.createElement('script');
		this.script.id = 'googleMaps';
		this.script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyB9WSqBSRYQKS17MPkIQ0r4_J1jXn5pENM&libraries=places';
		this.script.defer = true;

		this.script.addEventListener('load', () => this.initMap());

		document.body.appendChild(this.script);
	}

	renderMap()
	{
		this.map = new window.google.maps.Map(this.map, {
			zoom: 16,
			mapTypeControl: false,
			fullscreenControl: false
		});

		this.bounds = new window.google.maps.LatLngBounds();

		this.businesses.forEach((business) =>
		{
			const location = {
				lat: parseFloat(business.dataset.lat),
				lng: parseFloat(business.dataset.lng)
			};

			const icon = {
				url: window.baseUrl + 'assets/images/marker.png',
				size: new window.google.maps.Size(40, 40),
				scaledSize: new window.google.maps.Size(40, 40),
				anchor: new window.google.maps.Point(20, 20)
			};

			if(business.dataset.icon)
				icon.url = business.dataset.icon;

			const marker = new window.google.maps.Marker({
				map: this.map,
				position: location,
				icon: icon
			});

			const contentString = `
<div class="content">\
	<a href="${business.dataset.url}" class="underline">${business.dataset.title}</a>\
</div>`;

			const infoWindow = new window.google.maps.InfoWindow({
				content: contentString
			});

			marker.addListener('click', () => infoWindow.open(this.map, marker));

			this.bounds.extend(marker.getPosition());
		});

		this.map.fitBounds(this.bounds);

		this.initLocation();
		this.drawRadius();
	}

	initLocation()
	{
		this.location = document.getElementById('location');
		this.coordinates = document.getElementById('coordinates');
		this.distance = document.getElementById('distance');

		this.location.addEventListener('keydown', (e) =>
		{
			if(e.keyCode === 13)
			{
				e.preventDefault();
				return;
			}
		});

		this.autocomplete = new window.google.maps.places.Autocomplete(this.location, {
			componentRestrictions: {
				country: 'UK'
			}
		});

		this.autocomplete.addListener('place_changed', () =>
		{
			const place = this.autocomplete.getPlace();

			if(place.geometry)
			{
				const lat = place.geometry.location.lat();
				const lng = place.geometry.location.lng();

				this.coordinates.value = `${lat},${lng}`;
			}
		});
	}

	drawRadius()
	{
		if(this.location.value === '' || this.coordinates.value === '')
			return;

		const coordinates = this.coordinates.value.split(',');
		const radius = parseFloat(this.distance.value) * 1609.34;

		this.circle = new window.google.maps.Circle({
			map: this.map,
			center: {
				lat: parseFloat(coordinates[0]),
				lng: parseFloat(coordinates[1])
			},
			radius: radius,
			fillColor: '#da484e',
			fillOpacity: 0.1,
			strokeColor: '#da484e',
			strokeOpacity: 0.5,
			strokeWeight: 2
		});

		this.bounds = new window.google.maps.LatLngBounds();
		this.bounds.union(this.circle.getBounds());
		this.map.fitBounds(this.bounds);
	}
}
