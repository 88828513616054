export default class Modals
{
	constructor()
	{
		this.modals = document.querySelectorAll('.modal');
		this.modals.length && this.initModals();
	}

	initModals()
	{
		this.modals.forEach((modal) =>
		{
			const bg = modal.querySelector('.bg');
			const close = modal.querySelector('.close');

			if(bg)
				bg.addEventListener('click', this.closeModal.bind(this, modal));

			if(close)
				close.addEventListener('click', this.closeModal.bind(this, modal));
		});

		window.addEventListener('keyup', (e) =>
		{
			if(e.keyCode === 27)
			{
				const modals = document.querySelectorAll('.modal.active');
				const modal = modals[modals.length - 1];

				this.closeModal(modal);
			}
		});
	}

	closeModal(modal)
	{
		modal.classList.remove('active');
	}
}
