export default class Map
{
	constructor()
	{
		this.map = document.getElementById('map');
		this.maps = document.querySelectorAll('.map');

		if(this.map !== null || this.maps.length)
			this.initMap();
	}

	initMap()
	{
		const script = document.getElementById('googleMaps');

		if(script)
		{
			if(typeof window.google === 'undefined')
			{
				setTimeout(() => this.initMap(), 100);
				return;
			}

			if(this.map !== null)
				this.renderMap();

			if(this.maps.length)
				this.renderMaps();

			return;
		}

		this.script = document.createElement('script');
		this.script.id = 'googleMaps';
		this.script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyB9WSqBSRYQKS17MPkIQ0r4_J1jXn5pENM';
		this.script.defer = true;

		this.script.addEventListener('load', () => this.initMap());

		document.body.appendChild(this.script);
	}

	renderMap()
	{
		if(!this.maps.length)
		{
			const location = {
				lat: parseFloat(this.map.dataset.lat),
				lng: parseFloat(this.map.dataset.lng)
			};

			this.map = new window.google.maps.Map(this.map, {
				center: location,
				zoom: 16,
				disableDefaultUI: true
			});

			this.icon = {
				url: window.baseUrl + 'assets/images/pointer.png',
				size: new window.google.maps.Size(27, 43),
				scaledSize: new window.google.maps.Size(27, 43),
				anchor: new window.google.maps.Point(13.5, 43)
			};

			if(window.businessInfo.icon !== '')
			{
				this.icon = {
					url: window.businessInfo.icon,
					size: new window.google.maps.Size(40, 40),
					scaledSize: new window.google.maps.Size(40, 40),
					anchor: new window.google.maps.Point(20, 20)
				};
			}

			this.bounds = new window.google.maps.LatLngBounds();

			this.marker = new window.google.maps.Marker({
				map: this.map,
				position: location,
				icon: this.icon
			});

			if(window.businessInfo.endLocation)
			{
				this.endMarker = new window.google.maps.Marker({
					map: this.map,
					position: {
						lat: parseFloat(window.businessInfo.endLocation.lat),
						lng: parseFloat(window.businessInfo.endLocation.lng)
					},
					icon: this.icon
				});

				this.bounds.extend(this.marker.getPosition());
				this.bounds.extend(this.endMarker.getPosition());

				this.map.fitBounds(this.bounds);
			}

			return;
		}

		this.map = new window.google.maps.Map(this.map, {
			zoom: 16,
			disableDefaultUI: window.location.href.indexOf('?print') > -1
		});

		this.directionsService = new window.google.maps.DirectionsService();

		this.directionsDisplay = new window.google.maps.DirectionsRenderer({
			polylineOptions: {
				strokeColor: '#cb7295'
			},
			suppressMarkers: window.outerWidth >= 768
		});

		this.directionsDisplay.setMap(this.map);

		const waypoints = [];

		for(let i = 1; i < this.maps.length - 1; i++)
		{
			const waypoint = this.maps[i];

			waypoints.push({
				location: {
					lat: parseFloat(waypoint.dataset.lat),
					lng: parseFloat(waypoint.dataset.lng)
				}
			});
		}

		const request = {
			origin: {
				lat: parseFloat(this.maps[0].dataset.lat),
				lng: parseFloat(this.maps[0].dataset.lng)
			},
			destination: {
				lat: parseFloat(this.maps[this.maps.length - 1].dataset.lat),
				lng: parseFloat(this.maps[this.maps.length - 1].dataset.lng)
			},
			waypoints: waypoints,
			travelMode: 'DRIVING'
		};

		this.directionsService.route(request, (result, status) =>
		{
			if(status === 'OK')
				this.directionsDisplay.setDirections(result);
		});

		CustomMarker.prototype = new window.google.maps.OverlayView();

		CustomMarker.prototype.draw = function()
		{
			let div = this.div;

			if(!div)
			{
				div = this.div = document.createElement('div');

				div.className = 'map-overlay card white-bg no-grow shadow';

				div.style.position = 'absolute';
				div.style.width = '230px';
				div.style.height = '40px';

				let icon = window.baseUrl + 'assets/images/marker.png';

				if(this.args.icon)
					icon = this.args.icon;

				div.innerHTML = `
					<div class="split fill equal content">
						<div class="sixth">
							<div class="img fill cover lazy" style="background-image:url(${icon});"></div>
						</div>

						<div class="two-thirds center-column">
							<div class="padding-left">
								<p class="dark-purple small bold small-line-height ellipsis">${this.args.title}</p>
								<small class="block purple">${this.args.duration} mins here</small>
							</div>
						</div>

						<div class="sixth center center-column">
							<div class="counter light-grey-bg dark-purple trend">${this.args.index}</div>
						</div>
					</div>
				`;

				const panes = this.getPanes();
				panes.overlayImage.appendChild(div);
			}

			const point = this.getProjection().fromLatLngToDivPixel(this.latLng);

			if(point)
			{
				div.style.left = (point.x - 20) + 'px';
				div.style.top = (point.y - 20) + 'px';
			}
		};

		if(window.outerWidth < 768)
			return;

		this.items = document.querySelectorAll('.item[data-lat]');

		this.items.forEach((item, i) =>
		{
			new CustomMarker(
				new window.google.maps.LatLng(parseFloat(item.dataset.lat), parseFloat(item.dataset.lng)),
				this.map,
				{
					index: i + 1,
					icon: item.dataset.icon,
					title: item.dataset.title,
					duration: item.dataset.duration
				}
			);
		});
	}

	renderMaps()
	{
		this.maps.forEach((map) =>
		{
			const location = {
				lat: parseFloat(map.dataset.lat),
				lng: parseFloat(map.dataset.lng)
			};

			const el = new window.google.maps.Map(map, {
				center: location,
				zoom: 13,
				disableDefaultUI: true
			});

			const icon = {
				url: window.baseUrl + 'assets/images/marker.png',
				size: new window.google.maps.Size(40, 40),
				scaledSize: new window.google.maps.Size(40, 40),
				anchor: new window.google.maps.Point(20, 20)
			};

			if(map.dataset.icon)
				icon.url = map.dataset.icon;

			new window.window.google.maps.Marker({
				map: el,
				position: location,
				icon: icon
			});
		});
	}
}

function CustomMarker(latLng, map, args)
{
	this.latLng = latLng;
	this.args = args;
	this.setMap(map);
}
