export default class Menu
{
	constructor()
	{
		this.initMenu();
	}

	initMenu()
	{
		this.toggle = document.getElementById('menu_toggle');
		this.bg = document.getElementById('menu_bg');

		this.bg.addEventListener('click', this.closeMenu.bind(this));

		window.addEventListener('keyup', (e) =>
		{
			if(e.keyCode === 27)
				this.closeMenu();
		});
	}

	closeMenu()
	{
		this.toggle.checked = false;
	}
}
