import Lazy from 'ether-lazy';
import Pikaday from 'pikaday';

export default class Create
{
	constructor()
	{
		this.create = document.getElementById('create');
		this.create !== null && this.initCreate();
	}

	initCreate()
	{
		this.initOpen();
		this.initForms();
		this.initOptions();
		this.initSearch();
		this.initURL();
		this.initSubmit();
	}

	initOpen()
	{
		this.openCreate = document.querySelectorAll('.open-create');

		this.openCreate.forEach((open) =>
		{
			open.addEventListener('click', () =>
			{
				if(open.classList.contains('open-date'))
				{
					const date = new Date(open.dataset.date * 1000);
					const year = date.getFullYear().toString();
					let month = date.getMonth().toString();
					let day = date.getDate().toString();

					if(month.count < 2)
						month = `0${month}`;

					if(day.count < 2)
						day = `0${day}`;

					const formattedDate = `${year}-${month}-${day}`;
					this.picker.setDate(formattedDate);
				}

				this.openCreateModal();
			});
		});
	}

	openCreateModal()
	{
		this.initMap();
		this.create.classList.add('active');
	}

	initForms()
	{
		this.forms = this.create.querySelectorAll('form');

		this.forms.forEach((form) =>
		{
			if(form.id !== 'submit')
			{
				form.addEventListener('submit', (e) =>
				{
					e.preventDefault();
					return;
				});
			}
		});
	}

	initOptions()
	{
		this.startDay = this.create.querySelector('#startDay');
		this.startDayInput = this.create.querySelector('.startDay');

		this.startTime = this.create.querySelector('#startTime');
		this.startTimeInput = this.create.querySelector('.startTime');

		this.startDayInput.addEventListener('change', () =>
		{
			this.startDay.value = this.startDayInput.value;
			this.fetchResults();
		});

		this.startTimeInput.addEventListener('change', () =>
		{
			this.startTime.value = this.startTimeInput.value;
			this.fetchResults();
		});

		const opts = {
			field: document.getElementById('dateInput'),
			showDaysInNextAndPreviousMonths: true,
			firstDay: 1,
			setDefaultDate: true,
			defaultDate: new Date(),
			minDate: new Date(),
			format: 'dddd Do MMM'
		};

		if(window.businessInfo.minDate)
		{
			opts.minDate = new Date(window.businessInfo.minDate * 1000);
			opts.maxDate = new Date(window.businessInfo.maxDate * 1000);
		}

		this.picker = new Pikaday(opts);

		this.startDay.value = this.startDayInput.value;
		this.startTime.value = this.startTimeInput.value;
	}

	initSearch()
	{
		this.selectedIds = [];
		this.selected = [];

		this.results = document.getElementById('results');
		this.query = document.getElementById('query');

		this.query.addEventListener('input', (e) =>
		{
			if(e.keyCode === 13)
			{
				e.preventDefault();
				return;
			}

			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => this.fetchResults(), 500);
		});

		this.type = document.getElementById('type');
		this.type.addEventListener('change', () => this.fetchResults());

		this.distance = document.getElementById('distance');

		this.distance.addEventListener('change', () =>
		{
			this.mobileDistance.value = this.distance.value;
			this.fetchResults();
		});

		this.mobileDistance = document.getElementById('mobileDistance');

		this.mobileDistance.addEventListener('change', () =>
		{
			this.distance.value = this.mobileDistance.value;
			this.fetchResults();
		});
	}

	initURL()
	{
		if(window.location.hash === '#openCreate' && window.location.href.indexOf('user-trail') === -1)
			this.openCreateModal();

		window.addEventListener('hashchange', () =>
		{
			if(window.location.hash !== '#openCreate' && window.location.href.indexOf('user-trail') === -1)
				return;

			this.openCreateModal();
		}, false);
	}

	initMap()
	{
		if(this.init)
			return;

		this.map = document.getElementById('map_create');

		const script = document.getElementById('googleMaps');

		if(script)
		{
			if(typeof window.google === 'undefined')
			{
				setTimeout(() => this.initMap(), 100);
				return;
			}

			this.renderMap();

			return;
		}

		this.script = document.createElement('script');
		this.script.id = 'googleMaps';
		this.script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyB9WSqBSRYQKS17MPkIQ0r4_J1jXn5pENM&libraries=places';
		this.script.defer = true;

		this.script.addEventListener('load', () => this.initMap());

		document.body.appendChild(this.script);
	}

	renderMap()
	{
		this.location = {
			lat: window.businessInfo.location.lat,
			lng: window.businessInfo.location.lng
		};

		this.map = new window.google.maps.Map(this.map, {
			center: this.location,
			zoom: 16,
			mapTypeControl: false,
			fullscreenControl: false
		});

		this.icon = {
			url: window.baseUrl + 'assets/images/pointer-origin.png',
			size: new window.google.maps.Size(27, 43),
			scaledSize: new window.google.maps.Size(27, 43),
			anchor: new window.google.maps.Point(13.5, 43)
		};

		this.marker = new window.google.maps.Marker({
			map: this.map,
			position: this.location,
			icon: this.icon
		});

		this.infoWindow = new window.google.maps.InfoWindow({
			content: `${window.businessInfo.title} (origin)`
		});

		this.marker.addListener('mouseover', () => this.mouseoverMarker(this.infoWindow, this.marker));
		this.marker.addListener('mouseout', () => this.mouseoutMarker(this.infoWindow, this.marker));

		this.initEdit();
		this.fetchResults();

		this.init = true;
	}

	fetchResults()
	{
		if(this.markers)
			this.markers.map((marker) => marker.setMap(null));

		this.markers = [];
		this.results.innerHTML = '<h6 class="orange margin-left-small padding-top-small">Searching&hellip;</h6>';

		let url = window.baseUrl + 'businesses/query';

		const exclude = window.businessInfo.id;
		const address = encodeURI(window.businessInfo.location.address);
		const day = this.startDay.value;
		const time = this.startTime.value;
		const distance = this.distance.value;
		const query = this.query.value;
		const type = this.type.value;

		url += `?exclude=${exclude}`;
		url += `&location=${address}`;
		url += `&day=${day}`;
		url += `&time=${time}`;
		url += `&distance=${distance}`;

		if(query !== '')
			url += `&query=${query}`;

		if(type !== '')
			url += `&type=${type}`;

		fetch(url)
		.then((response) => {
			return response.text();
		})
		.then((response) =>
		{
			this.results.innerHTML = response;
			this.businesses = this.results.querySelectorAll('.business');

			this.businesses.forEach((business) =>
			{
				const id = parseInt(business.dataset.id);

				if(this.selectedIds.indexOf(id) > -1)
					business.classList.add('light-grey-bg');
			});

			this.drawMarkers();
			this.drawRadius();

			new Lazy();
		});
	}

	drawMarkers()
	{
		this.businesses.forEach((business) =>
		{
			if(business.classList.contains('no-pointer'))
				return;

			const location = {
				lat: parseFloat(business.dataset.lat),
				lng: parseFloat(business.dataset.lng)
			};

			this.icon.url = window.baseUrl + 'assets/images/pointer.png';

			const marker = new window.google.maps.Marker({
				map: this.map,
				position: location,
				icon: this.icon
			});

			const infoWindow = new window.google.maps.InfoWindow({
				content: business.dataset.title
			});

			marker.addListener('mouseover', () => this.mouseoverMarker(infoWindow, marker));
			business.addEventListener('mouseover', () => this.mouseoverMarker(infoWindow, marker));

			marker.addListener('mouseout', () => this.mouseoutMarker(infoWindow, marker));
			business.addEventListener('mouseout', () => this.mouseoutMarker(infoWindow, marker));

			marker.addListener('click', () => this.clickMarker(business));
			business.addEventListener('click', () => this.clickMarker(business));

			this.markers.push(marker);
		});
	}

	mouseoverMarker(infoWindow, marker)
	{
		infoWindow.open(this.map, marker);
		this.icon.url = window.baseUrl + 'assets/images/pointer-hover.png';
		marker.setIcon(this.icon);
	}

	mouseoutMarker(infoWindow, marker)
	{
		infoWindow.close(this.map, marker);
		this.icon.url = window.baseUrl + 'assets/images/pointer.png';
		marker.setIcon(this.icon);
	}

	clickMarker(business)
	{
		business.classList.toggle('light-grey-bg');
		this.toggleSelected(business);
	}

	drawRadius()
	{
		if(this.location === undefined)
			return;

		const radius = parseInt(this.distance.value) * 1609.34;

		if(this.circle)
			this.circle.setMap(null);

		this.circle = new window.google.maps.Circle({
			map: this.map,
			center: {
				lat: this.location.lat,
				lng: this.location.lng
			},
			radius: radius,
			fillColor: '#da484e',
			fillOpacity: 0.1,
			strokeColor: '#da484e',
			strokeOpacity: 0.5,
			strokeWeight: 2
		});

		this.map.fitBounds(this.circle.getBounds());
	}

	toggleSelected(business)
	{
		const id = parseInt(business.dataset.id);
		const index = this.selectedIds.indexOf(id);

		if(index > -1)
		{
			this.selectedIds.splice(index, 1);
			this.selected.splice(index, 1);

			this.renderSelected();

			return;
		}

		this.selectedIds.push(id);
		this.selected.push(business);

		this.renderSelected();
	}

	renderSelected()
	{
		if(!this.selectedBusinesses)
		{
			this.selectedBusinesses = document.getElementById('selectedBusinesses');
			this.defaultSelectedHTML = this.selectedBusinesses.innerHTML;
		}

		this.selectedBusinesses.innerHTML = this.defaultSelectedHTML;

		if(!this.fields)
		{
			this.fields = document.getElementById('fields');
			this.defaultFieldsHTML = this.fields.innerHTML;
		}

		this.fields.innerHTML = this.defaultFieldsHTML;

		const alphabet = 'bcdefghijklmnopqrstuvwxyz'.split('');

		this.selected.forEach((business, i) =>
		{
			const letter = alphabet[i].toUpperCase();

			this.selectedBusinesses.innerHTML = this.selectedBusinesses.innerHTML + `
				<div class="selected-business padding-left padding-right margin-top-small">
					<div class="split">
						<div class="four-fifths split">
							<div class="fifth">
								<div class="letter trend">${letter}</div>
							</div>

							<div class="four-fifths">
								<p class="trend small small-line-height">${business.dataset.title}</p>
								<small class="trend block">${parseFloat(business.dataset.time) / 60} hour(s) here</small>
							</div>
						</div>

						<div class="fifth text-right">
							<button class="remove contain lazy" data-src="${window.baseUrl + '/assets/images/remove.png'}" data-i="${i}"></button>
						</div>
					</div>
				</div>
			`;

			const matrixIndex = i + this.fields.children.length + 2;

			this.fields.innerHTML = this.fields.innerHTML + `
				<div class="stop">
					<input type="hidden" name="fields[itinerary][new${matrixIndex}][type]" value="business" />
					<input type="hidden" name="fields[itinerary][new${matrixIndex}][enabled]" value="4" />
					<input type="hidden" name="fields[itinerary][new${matrixIndex}][fields][business][]" value="${business.dataset.id}" />
					<input type="hidden" name="fields[itinerary][new${matrixIndex}][fields][duration][locale]" value="en-GB" />
					<input type="hidden" name="fields[itinerary][new${matrixIndex}][fields][duration][value]" value="${business.dataset.time}" />
				</div>
			`;
		});

		this.selectedBusinesses.querySelectorAll('.remove').forEach((remove) =>
		{
			remove.addEventListener('click', () =>
			{
				const index = parseInt(remove.dataset.i);
				const selected = this.selected[index];

				this.clickMarker(selected);
			});
		});

		if(!this.preselected || (this.preselected && this.editInit))
			this.drawRoute();

		new Lazy();
	}

	drawRoute()
	{
		if(!this.directionsService)
			this.directionsService = new window.google.maps.DirectionsService();

		if(this.directionsDisplay)
			this.directionsDisplay.setMap(null);

		if(!this.selected.length)
			return;

		this.directionsDisplay = new window.google.maps.DirectionsRenderer({
			map: this.map,
			preserveViewport: true,
			polylineOptions: {
				strokeColor: '#cb7295'
			}
		});

		const waypoints = [];

		for(let i = 0; i < this.selected.length - 1; i++)
		{
			const waypoint = this.selected[i];

			waypoints.push({
				location: {
					lat: parseFloat(waypoint.dataset.lat),
					lng: parseFloat(waypoint.dataset.lng)
				}
			});
		}

		const request = {
			origin: {
				lat: window.businessInfo.location.lat,
				lng: window.businessInfo.location.lng
			},
			destination: {
				lat: parseFloat(this.selected[this.selected.length - 1].dataset.lat),
				lng: parseFloat(this.selected[this.selected.length - 1].dataset.lng)
			},
			waypoints: waypoints,
			travelMode: 'DRIVING'
		};

		this.directionsService.route(request, (result, status) =>
		{
			if(status !== 'OK')
				return;

			this.directionsDisplay.setDirections(result);

			const legs = result.routes[0].legs;

			legs.map((leg, i) =>
			{
				const stop = this.fields.children[i + 1];
				const duration = Math.round(leg.duration.value / 60);
				const matrixIndex = Array.from(this.fields.children).indexOf(stop) + i + 1;

				stop.innerHTML = `
					<input type="hidden" name="fields[itinerary][new${matrixIndex}][type]" value="travelTime" />
					<input type="hidden" name="fields[itinerary][new${matrixIndex}][enabled]" value="1" />
					<input type="hidden" name="fields[itinerary][new${matrixIndex}][fields][travelTime][new1][type]" value="4" />
					<input type="hidden" name="fields[itinerary][new${matrixIndex}][fields][travelTime][new1][fields][transport]" value="car" />
					<input type="hidden" name="fields[itinerary][new${matrixIndex}][fields][travelTime][new1][fields][duration][locale]" value="en-GB" />
					<input type="hidden" name="fields[itinerary][new${matrixIndex}][fields][travelTime][new1][fields][duration][value]" value="${duration}" />
					<input type="hidden" name="fields[itinerary][new${matrixIndex}][fields][travelTime][new1][fields][notes]" value="" />
				` + stop.innerHTML;
			});
		});
	}

	initSubmit()
	{
		this.finish = document.getElementById('finish');

		this.title = this.create.querySelector('#title');
		this.titleInput = this.finish.querySelector('.title');
		this.titleInput.addEventListener('input', () => this.title.value = this.titleInput.value);

		this.continue = this.create.querySelector('button[type="submit"]');

		this.continue.addEventListener('click', (e) =>
		{
			if(!this.selected.length)
			{
				e.preventDefault();
				alert('You must select at least one other location to create a trail.');
				return;
			}

			if(this.startDayInput.value === '' || this.startTimeInput.value === '')
			{
				e.preventDefault();
				alert('You must select a start day and time to create a trail.');
				return;
			}

			if(!this.finish.classList.contains('active'))
			{
				e.preventDefault();

				this.finish.classList.add('active');
				this.titleInput.focus();

				return;
			}
		});

		this.save = this.finish.querySelector('form');

		this.save.addEventListener('submit', (e) =>
		{
			e.preventDefault();

			if(this.title.value === '')
			{
				alert('You must enter a title for your trail.');
				return;
			}

			this.continue.click();
			return;
		});
	}

	initEdit()
	{
		if(this.editInit)
			return;

		this.preselected = document.querySelectorAll('.item[data-id]');

		this.preselected.forEach((business, i) =>
		{
			if(i === 0)
				return;

			this.toggleSelected(business);
		});

		this.drawRoute();

		this.editInit = true;
	}
}
