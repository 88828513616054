import Animate from 'ether-animate';
import BusinessListing from './BusinessListing';
import Copy from './Copy';
import Create from './Create';
import Filters from './Filters';
import Gallery from 'ether-gallery';
import Lazy from 'ether-lazy';
import Map from './Map';
import Menu from './Menu';
import Modals from './Modals';
import SVG from './SVG';
import Trail from './Trail';

function init()
{
	new Animate();
	new BusinessListing();
	new Copy();
	new Create();
	new Filters();
	new Gallery();
	new Lazy();
	new Map();
	new Menu();
	new Modals();
	new SVG();
	new Trail();
}

init();
