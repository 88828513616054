export default class Trail
{
	constructor()
	{
		this.expand = document.getElementById('expandTrail');
		this.expand !== null && this.initTrail();
	}

	initTrail()
	{
		this.toggle = document.querySelector('.hide.mobile-hide');
		this.expand.addEventListener('click', this.toggleExpand.bind(this));
	}

	toggleExpand()
	{
		this.toggle.classList.toggle('hide');
		this.toggle.classList.toggle('mobile-hide');

		this.expand.classList.toggle('expanded');

		if(this.expand.classList.contains('expanded'))
		{
			this.expand.innerText = 'Hide full trail';
			return;
		}

		this.expand.innerText = 'View full trail';
	}
}
