export default class Copy
{
	constructor()
	{
		this.btns = document.querySelectorAll('.copy');
		this.btns.length && this.initCopy();
	}

	initCopy()
	{
		this.btns.forEach((btn) =>
		{
			btn.addEventListener('click', () =>
			{
				navigator.clipboard.writeText(btn.dataset.text);
				
				btn.classList.add('active');
				setTimeout(() => btn.classList.remove('active'), 2000);
			});
		});
	}
}
