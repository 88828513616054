export default class BusinessListing
{
	constructor()
	{
		this.businessListing = document.getElementById('businessListing');
		this.businessListing !== null && this.initBusinessListing();
	}

	initBusinessListing()
	{
		this.initBusinessSwitch();
		this.initGoogle();
		this.initAssetFields();
		this.initMultiSelect();
		this.initRedactor();
		this.initHours();
		this.initSubmit();
	}

	initBusinessSwitch()
	{
		this.businessSwitch = document.getElementById('businessId');

		if(this.businessSwitch !== null)
			this.businessSwitch.addEventListener('change', () => this.businessSwitch.parentNode.submit());
	}

	initGoogle()
	{
		const script = document.getElementById('googleMaps');

		if(script)
		{
			if(typeof window.google === 'undefined')
			{
				setTimeout(() => this.initGoogle(), 100);
				return;
			}

			this.initLocation();
			return;
		}

		this.script = document.createElement('script');
		this.script.id = 'googleMaps';
		this.script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyB9WSqBSRYQKS17MPkIQ0r4_J1jXn5pENM&libraries=places';
		this.script.defer = true;

		this.script.addEventListener('load', () => this.initLocation());

		document.body.appendChild(this.script);
	}

	initLocation()
	{
		this.location = document.getElementById('location');
		this.lat = document.getElementById('lat');
		this.lng = document.getElementById('lng');

		this.location.addEventListener('keydown', (e) =>
		{
			if(e.keyCode === 13)
			{
				e.preventDefault();
				return;
			}
		});

		this.autocomplete = new window.google.maps.places.Autocomplete(this.location, {
			componentRestrictions: {
				country: 'UK'
			}
		});

		this.autocomplete.addListener('place_changed', () =>
		{
			const place = this.autocomplete.getPlace();

			if(place.geometry)
			{
				const lat = place.geometry.location.lat();
				const lng = place.geometry.location.lng();

				this.lat.value = lat;
				this.lng.value = lng;
			}
		});
	}

	initAssetFields()
	{
		this.assets = this.businessListing.querySelectorAll('.asset');

		this.assets.forEach((asset) =>
		{
			const input = asset.querySelector('input');
			input.addEventListener('change', () => this.renderFiles(input));

			const removeBtns = asset.parentNode.querySelectorAll('.remove');
			removeBtns.forEach((btn) => btn.addEventListener('click', () => this.removeFile(btn)));
		});
	}

	renderFiles(input)
	{
		if(input.files.length > 6)
		{
			alert('You have exceeded the 6 photo limit.');
			input.files.slice(0, 6);
			return;
		}

		const selected = input.parentNode.previousSibling;

		const added = selected.querySelectorAll('.added');
		added.forEach((add) => add.parentNode.removeChild(add));

		Array.from(input.files).map((file) =>
		{
			const reader = new FileReader();

			reader.onload = (e) =>
			{
				let html = '';
				let remove = '';

				if(input.multiple)
				{
					html = selected.innerHTML;

					/*
					remove = `
						<div class="eighth padding-left padding-right-small text-right">
							<div
								class="remove aspect-square contain pointer"
								data-i="${i}"
								style="background-image:url('${window.baseUrl}assets/images/delete.png')"
							></div>
						</div>
					`;
					*/
				}

				selected.innerHTML = html + `
					<div class="selected-asset added card no-grow light-grey-bg margin-bottom-small">
						<div class="split center">
							<div class="eighth card no-grow">
								<div class="img dark-purple-bg aspect-square cover" style="background-image:url(${e.target.result})"></div>
							</div>

							<div class="three-quarters padding-left-small ellipsis">${file.name}</div>

							${remove}
						</div>
					</div>
				`;

				const removeBtns = selected.querySelectorAll('.remove');
				removeBtns.forEach((btn) => btn.addEventListener('click', () => this.removeFile(btn)));
			};

			reader.readAsDataURL(file);
		});
	}

	removeFile(btn)
	{
		/*
		if(btn.dataset.i !== undefined)
		{
			const i = parseInt(btn.dataset.i);
			const input = btn.closest('.assets').querySelector('input[type="file"]');

			input.files.slice(i, 1);

			return;
		}
		*/

		const parent = btn.closest('.selected-asset');
		parent.parentNode.removeChild(parent);
	}

	initMultiSelect()
	{
		const css = document.createElement('link');

		css.rel = 'stylesheet';
		css.type = 'text/css';
		css.href = `${window.baseUrl}assets/css/_inc/choices.css`;

		document.body.appendChild(css);

		const choices = document.createElement('script');

		choices.type = 'text/javascript';
		choices.src = `${window.baseUrl}assets/js/_inc/choices.js`;
		choices.defer = true;

		choices.addEventListener('load', () =>
		{
			new window.Choices('select:not(#businessId):not(.limit)', {
				removeItems: true,
				removeItemButton: true
			});

			new window.Choices('select.limit', {
				removeItems: true,
				removeItemButton: true,
				maxItemCount: 3
			});
		});

		document.body.appendChild(choices);
	}

	initRedactor()
	{
		const css = document.createElement('link');

		css.rel = 'stylesheet';
		css.type = 'text/css';
		css.href = `${window.baseUrl}assets/css/_inc/redactor.css`;

		document.body.appendChild(css);

		const redactor = document.createElement('script');

		redactor.type = 'text/javascript';
		redactor.src = `${window.baseUrl}assets/js/_inc/redactor.js`;
		redactor.defer = true;

		redactor.addEventListener('load', () =>
		{
			const limiter = document.createElement('script');

			limiter.type = 'text/javascript';
			limiter.src = `${window.baseUrl}assets/js/_inc/limiter.js`;
			limiter.defer = true;

			limiter.addEventListener('load', () =>
			{
				window.$R('#intro', {
					toolbar: false,
					plugins: ['limiter'],
					limiter: 200
				});

				window.$R('#description', {
					toolbar: false,
					plugins: ['limiter'],
					limiter: 800
				});

				document.querySelectorAll('.redactor-voice-label').forEach((label) => label.parentNode.removeChild(label));
			});

			document.body.appendChild(limiter);
		});

		document.body.appendChild(redactor);
	}

	initHours()
	{
		this.hours = document.getElementById('hours');
		this.additionalHours = document.getElementById('additionalHours');

		this.appointmentOnly = document.getElementById('appointmentOnly');
		this.appointmentOnlyInput = document.getElementById('appointmentOnlyInput');

		this.appointmentOnly.addEventListener('change', () => this.toggleHours());
		this.toggleHours();

		this.multipleOpenings = document.getElementById('multipleOpenings');
		this.multipleOpeningsInput = document.getElementById('multipleOpeningsInput');

		this.multipleOpenings.addEventListener('change', () => this.toggleAdditional());
		this.toggleAdditional();
	}

	toggleHours()
	{
		let display = 'block';
		let value = '';

		if(this.appointmentOnly.checked)
		{
			display = 'none';
			value = '1';
		}

		this.hours.style.display = display;
		this.appointmentOnlyInput.value = value;
	}

	toggleAdditional()
	{
		let display = 'none';
		let value = '';

		if(this.multipleOpenings.checked)
		{
			display = 'block';
			value = '1';
		}

		this.additionalHours.style.display = display;
		this.multipleOpeningsInput.value = value;
	}

	initSubmit()
	{
		this.form = document.getElementById('businessListing');
		this.preview = document.getElementById('preview');

		this.redirect = this.form.querySelector('#redirect');
		this.redirectPreview = this.form.querySelector('#redirectPreview');
		this.redirectPreviewClone = this.redirectPreview.cloneNode();

		this.redirectPreview.parentNode.removeChild(this.redirectPreview);

		this.preview.addEventListener('click', (e) =>
		{
			e.preventDefault();

			this.redirect.value = this.redirectPreviewClone.value;
			this.form.submit();
		});
	}
}
